var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "Основное", active: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "Name" } }, [_vm._v("Имя")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.apiResource.name,
                        expression: "apiResource.name"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("name"),
                    attrs: {
                      type: "text",
                      id: "Name",
                      name: "name",
                      placeholder: "Имя",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.apiResource.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.apiResource, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.apiResource.enabled,
                        expression: "apiResource.enabled"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "Enabled" },
                    domProps: {
                      checked: Array.isArray(_vm.apiResource.enabled)
                        ? _vm._i(_vm.apiResource.enabled, null) > -1
                        : _vm.apiResource.enabled
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.apiResource.enabled,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.apiResource,
                                "enabled",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.apiResource,
                                "enabled",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.apiResource, "enabled", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "Enabled" }
                    },
                    [_vm._v("Включен")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "displayName" } }, [
                    _vm._v("Отоброжаемое имя")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.apiResource.displayName,
                        expression: "apiResource.displayName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("displayName"),
                    attrs: {
                      type: "text",
                      id: "displayName",
                      name: "displayName",
                      placeholder: "Отоброжаемое имя",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.apiResource.displayName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.apiResource,
                          "displayName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Клаймы")]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            _vm.addUserClaims()
                          }
                        }
                      },
                      [_vm._v("Добавить")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.apiResource.userClaims, function(item, index) {
                      return _c("div", { staticClass: "input-group mb-3" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: item },
                          on: {
                            input: function($event) {
                              _vm.updateUserClaim(index, $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-danger",
                              on: {
                                click: function($event) {
                                  _vm.removeUserClaim(index)
                                }
                              }
                            },
                            [_vm._v("Удалить")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("b-tab", { attrs: { title: "Scopes" } }, [
            _vm.editScopeState
              ? _c(
                  "div",
                  { staticClass: "add-scope" },
                  [
                    _c("edit-scope", {
                      attrs: { scope: _vm.currentScope },
                      on: {
                        updated: _vm.updateScopes,
                        cancel: _vm.cancelEditScope
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editScopeState,
                    expression: "!editScopeState"
                  }
                ]
              },
              [
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          _vm.editScope(null)
                        }
                      }
                    },
                    [_vm._v("Добавить")]
                  )
                ]),
                _vm._v(" "),
                _c("b-table", {
                  attrs: {
                    striped: "",
                    hover: "",
                    items: _vm.apiResource.scopes,
                    fields: _vm.scopeFields
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function(data) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  _vm.editScope(data.item)
                                }
                              }
                            },
                            [_vm._v("Редактировать")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.isSaving },
          on: {
            click: function($event) {
              _vm.save()
            }
          }
        },
        [_vm._v("Сохранить")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }