import httpService from './httpService';
import { OAuthClient, IdentityResource, ApiResource } from '../types/storeTypes';

class apiService {
    //Clients
    getApiClients() {
        return httpService.axios.get('Manage/getApiClients')
            .then(resp => {
                return resp.data;
            })
    }

    getClient(clientId: string) {
        return httpService.axios.get('Manage/getClient/' + clientId)
            .then(resp => {
                return resp.data;
            })
    }

    saveClient(client: OAuthClient) {
        return httpService.axios.post('Manage/saveClient', client )
            .then(resp => {
                return resp.data;
            });
    }

    //Identity resources
    getIdentityResources() {
        return httpService.axios.get('Manage/getIdentityResources')
            .then(resp => {
                return resp.data;
            })
    }

    getIdentityResource(name: string) {
        return httpService.axios.get('Manage/getIdentityResource/' + name)
            .then(resp => {
                return resp.data;
            })
    }

    saveIdentityResource(idResource: IdentityResource) {
        return httpService.axios.post('Manage/saveIdentityResource', idResource)
            .then(resp => {
                return resp.data;
            });
    }

    //Api resources
    getApiResources() {
        return httpService.axios.get('Manage/getApiResources')
            .then(resp => {
                return resp.data;
            })
    }

    getApiResource(name: string) {
        return httpService.axios.get('Manage/getApiResource/' + name)
            .then(resp => {
                return resp.data;
            })
    }

    saveApiResource(apiResource: ApiResource) {
        return httpService.axios.post('Manage/saveApiResource', apiResource)
            .then(resp => {
                return resp.data;
            });
    }

    getResources() {
        return httpService.axios.get('Manage/getResources')
            .then(resp => {
                return resp.data;
            })
    }

    referenceSiteUserToAddUser(username: string, password: string): Promise<any> {
        return httpService.axios.post("account/refLdapUser", { username, password });
    }

}

export default new apiService();