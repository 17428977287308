import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiResource } from '@/types/storeTypes';
import { Getter, Action } from 'vuex-class';
import { ActionMethod } from 'vuex';

@Component
export default class ApiResourseListPage extends Vue {

    @Getter('apiResources') apiResources: ApiResource[];
    @Getter('apiResourcesCount') count: number;

    @Action('getApiResources') getApiResources: ActionMethod;


    isLoading: boolean = false;

    addApiResource() {
        this.$router.push({ path: '/apiResource/' });
    }

    mounted() {
        this.isLoading = true;
        this.getApiResources()
            .finally(() => {
                this.isLoading = false;
            })
    }

    fields = {
        name: {
            label: 'Имя',
            sortable: true
        },
        displayName: {
            label: 'Отображаемое имя',
            sortable: true
        },
        description: {
            label: 'Описание',
            sortable: false
        },
        actions: {
            label: 'Действия'
        }
    }
}