var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "Основное", active: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "Name" } }, [_vm._v("Имя")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.name,
                        expression: "idResource.name"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("name"),
                    attrs: {
                      type: "text",
                      id: "Name",
                      name: "name",
                      placeholder: "Имя",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.idResource.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.idResource, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.enabled,
                        expression: "idResource.enabled"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "Enabled" },
                    domProps: {
                      checked: Array.isArray(_vm.idResource.enabled)
                        ? _vm._i(_vm.idResource.enabled, null) > -1
                        : _vm.idResource.enabled
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.idResource.enabled,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.idResource,
                                "enabled",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.idResource,
                                "enabled",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.idResource, "enabled", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "Enabled" }
                    },
                    [_vm._v("Включен")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.required,
                        expression: "idResource.required"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "Required" },
                    domProps: {
                      checked: Array.isArray(_vm.idResource.required)
                        ? _vm._i(_vm.idResource.required, null) > -1
                        : _vm.idResource.required
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.idResource.required,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.idResource,
                                "required",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.idResource,
                                "required",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.idResource, "required", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "Required" }
                    },
                    [_vm._v("Обязательный")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.emphasize,
                        expression: "idResource.emphasize"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "Emphasize" },
                    domProps: {
                      checked: Array.isArray(_vm.idResource.emphasize)
                        ? _vm._i(_vm.idResource.emphasize, null) > -1
                        : _vm.idResource.emphasize
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.idResource.emphasize,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.idResource,
                                "emphasize",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.idResource,
                                "emphasize",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.idResource, "emphasize", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "Emphasize" }
                    },
                    [_vm._v("Подчеркивать (особое значение)")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.showInDiscoveryDocument,
                        expression: "idResource.showInDiscoveryDocument"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "ShowInDiscoveryDocument" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.idResource.showInDiscoveryDocument
                      )
                        ? _vm._i(_vm.idResource.showInDiscoveryDocument, null) >
                          -1
                        : _vm.idResource.showInDiscoveryDocument
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.idResource.showInDiscoveryDocument,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.idResource,
                                "showInDiscoveryDocument",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.idResource,
                                "showInDiscoveryDocument",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.idResource,
                            "showInDiscoveryDocument",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "ShowInDiscoveryDocument" }
                    },
                    [_vm._v("Показывать в Discovery")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "displayName" } }, [
                    _vm._v("Отоброжаемое имя")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.idResource.displayName,
                        expression: "idResource.displayName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("displayName"),
                    attrs: {
                      type: "text",
                      id: "displayName",
                      name: "displayName",
                      placeholder: "Отоброжаемое имя",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.idResource.displayName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.idResource,
                          "displayName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Клаймы")]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            _vm.addUserClaims()
                          }
                        }
                      },
                      [_vm._v("Добавить")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.idResource.userClaims, function(item, index) {
                      return _c("div", { staticClass: "input-group mb-3" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: item },
                          on: {
                            input: function($event) {
                              _vm.updateUserClaim(index, $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-danger",
                              on: {
                                click: function($event) {
                                  _vm.removeUserClaim(index)
                                }
                              }
                            },
                            [_vm._v("Удалить")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.isSaving },
          on: {
            click: function($event) {
              _vm.save()
            }
          }
        },
        [_vm._v("Сохранить")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }