import Vue from 'vue'
import { Inject } from 'vue-property-decorator';
import { Validator } from 'vee-validate';

export default class VueValidation extends Vue {

    @Inject('$validator') $validator: Validator;

    getValidateClasses(fieldName: string) {
        const matcher = {
            name: fieldName,
        }
        return {
            'is-valid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.valid && this.$validator.fields.find(matcher).flags.dirty,
            'is-invalid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.invalid && this.$validator.fields.find(matcher).flags.dirty,
        }
    }

    get formInvalid() {
        return this.$validator.fields.items.some(field => field.flags.invalid);
    }
}