import "core-js";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./css/site.css";

import './hooks';

import "bootstrap";

import Vue, { PluginFunction } from 'vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VeeValidate, { install, Configuration } from 'vee-validate';
let veeValidateConfig: Configuration = {
    inject: false,
    fieldsBagName: 'formFields'
};
Vue.use(<PluginFunction<any>>VeeValidate.install, veeValidateConfig);

import store from './store';

import httpService from './common/httpService';
httpService.init();

import app from './App.vue';

import router from './router/router';

store.dispatch('getUser')
    .then(data => {
        console.log(data);
        if (!data) {
            //store.dispatch(LOGIN);
        }
        else {
            //var url = "http://localhost:5000/connect/userinfo";
            /*var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function () {
                console.log(xhr.status);
                console.log(JSON.parse(xhr.responseText))
            }
            xhr.setRequestHeader("Authorization", "Bearer " + data.access_token);
            xhr.send();*/
        }
    })
    .catch(error => {
        console.log(error);
        //store.dispatch(LOGIN);
    })
    .finally(() => {
        new Vue({
            el: '#app',
            store: store,
            router: router,
            render: h => h(app)
        })
    })

