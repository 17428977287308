import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { IdentityResource } from '@/types/storeTypes';
import { ActionMethod } from 'vuex';
import { Inject } from 'vue-property-decorator';
import { Validator } from 'vee-validate';
import { Route } from 'vue-router';

@Component
export default class IdentityResourceEditPage extends Vue {
    @Getter('identityResource') idResource: IdentityResource;

    @Action('getIdentityResource') getIdentityResource: ActionMethod;
    @Action('resetIdentityResource') resetIdentityResource: ActionMethod;
    @Action('saveIdentityResource') saveIdentityResource: ActionMethod;

    @Inject('$validator') $validator: Validator;

    isLoading: boolean = false;
    isSaving: boolean = false;

    mounted() {
        let identityResourceName = this.$route.params['name'];
        this.loadData(identityResourceName);
    }

    beforeRouteUpdate(to: Route, from: Route, next: any) {
        let identityResourceName = this.$route.params['name'];
        this.loadData(identityResourceName);
        next();
    }

    save() {
        this.isSaving = true;

        this.saveIdentityResource(this.idResource)
            .finally(() => {
                this.isSaving = false;
            })
    }

    /* User Claims */

    addUserClaims() {
        this.idResource.userClaims.push('');
    }

    updateUserClaim(index: number, claim: string) {
        this.$set(this.idResource.userClaims, index, claim);
    }

    removeUserClaim(index: number) {
        this.idResource.userClaims.splice(index, 1);
    }

    /* Validation */
    getValidateClasses(fieldName: string) {
        const matcher = {
            name: fieldName,
        }
        return {
            'is-valid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.valid && this.$validator.fields.find(matcher).flags.dirty,
            'is-invalid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.invalid && this.$validator.fields.find(matcher).flags.dirty,
        }
    }

    get formInvalid() {
        console.log(this.$validator.fields);

        return this.$validator.fields.items.some(field => field.flags.invalid);
        //return Object.keys(this.fields).some(key => this.fields[key].invalid);
    }

    private loadData(name: string) {
        if (name) {
            this.isLoading = true;
            this.getIdentityResource(name)
                .finally(() => {
                    this.isLoading = false;
                })
        }
        else {
            this.resetIdentityResource();
        }
    }
}