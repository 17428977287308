import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { OAuthClient, Secret, Resources } from '@/types/storeTypes';
import { Route } from 'vue-router';
import { Inject } from 'vue-property-decorator';
import { Validator } from 'vee-validate';
import { GrantTypes, GrantType } from '../../common/consts';
import EditSecret from '../../components/editSecret/editSecret.vue';
import { Promise } from 'core-js';

interface Indexable {
    [name: string]: string[]
}

@Component({
    components: {
        editSecret: EditSecret
    }
})
export default class EditOAuthClient extends Vue {
    isLoading: boolean = false;
    isSaving: boolean = false;

    @Inject('$validator') $validator: Validator;

    @Action getClient: ActionMethod;
    @Action resetClient: ActionMethod;
    @Action saveClient: ActionMethod;
    @Action getResources: ActionMethod;

    @Getter client: OAuthClient;

    @Getter resources: Resources;

    mounted() {
        let clientId = this.$route.params['clientId'];
        this.loadData(clientId);
    }

    beforeRouteUpdate(to: Route, from: Route, next: any) {
        let clientId = to.params['clientId'];
        this.loadData(clientId);
        next();
    }

    save() {
        this.isSaving = true;

        this.saveClient(this.client)
            .finally(() => {
                this.isSaving = false;
            })
    }

    /* Redirect Uris */
    addRedirectUri() {
        this.client.redirectUris.push('');
    }

    removeRedirectUri(index: number) {
        this.client.redirectUris.splice(index, 1);
    }

    updateRedirectUri(index: number, value: string) {
        this.$set(this.client.redirectUris, index, value);
    }

    /* Post Logout Redirect Uris */
    addPostLogoutRedirectUri() {
        this.client.postLogoutRedirectUris.push('');
    }

    removePostLogoutRedirectUri(index: number) {
        this.client.postLogoutRedirectUris.splice(index, 1);
    }

    updatePostLogoutRedirectUri(index: number, value: string) {
        this.$set(this.client.postLogoutRedirectUris, index, value);
    }

    /* Allowed Grant types */
    changeGrantTypes() {
        this.client.allowedGrantTypes = GrantTypes.ResourceOwnerPassword;
    }

    get selectedGrantTypes(): string {
        return this.getNameGrantTypes(this.client.allowedGrantTypes);
    }
    set selectedGrantTypes(value: string) {
        this.client.allowedGrantTypes = this.grantTypes[value];
    }

    private getNameGrantTypes(grantTypes: string[]): string {
        if (!grantTypes || grantTypes.length == 0) {
            return '';
        }
        if (grantTypes.indexOf(GrantType.Implicit) >= 0) {
            if (grantTypes.indexOf(GrantType.ClientCredentials) >= 0) {
                return "ImplicitAndClientCredentials";
            }
            else {
                return "Implicit";
            }
        }

        if (grantTypes.indexOf(GrantType.AuthorizationCode) >= 0) {
            if (grantTypes.indexOf(GrantType.ClientCredentials) >= 0) {
                return "CodeAndClientCredentials";
            }
            else {
                return "Code";
            }
        }

        if (grantTypes.indexOf(GrantType.Hybrid) >= 0) {
            if (grantTypes.indexOf(GrantType.ClientCredentials) >= 0) {
                return "HybridAndClientCredentials";
            }
            else {
                return "Hybrid";
            }
        }

        if (grantTypes.indexOf(GrantType.ResourceOwnerPassword) >= 0) {
            if (grantTypes.indexOf(GrantType.ClientCredentials) >= 0) {
                return "ResourceOwnerPasswordAndClientCredentials";
            }
            else {
                return "ResourceOwnerPassword";
            }
        }

        if (grantTypes.indexOf(GrantType.ClientCredentials) >= 0) {
            return "ClientCredentials"
        }

        return "";
    }

    grantTypes: Indexable = {
        "Implicit": GrantTypes.Implicit,
        "ImplicitAndClientCredentials": GrantTypes.ImplicitAndClientCredentials,
        "Code": GrantTypes.Code,
        "CodeAndClientCredentials": GrantTypes.CodeAndClientCredentials,
        "Hybrid": GrantTypes.Hybrid,
        "HybridAndClientCredentials": GrantTypes.HybridAndClientCredentials,
        "ClientCredentials": GrantTypes.ClientCredentials,
        "ResourceOwnerPassword": GrantTypes.ResourceOwnerPassword,
        "ResourceOwnerPasswordAndClientCredentials": GrantTypes.ResourceOwnerPasswordAndClientCredentials
    }

    /* Validation */
    getValidateClasses(fieldName: string) {
        const matcher = {
            name: fieldName,
        }
        return {
            'is-valid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.valid && this.$validator.fields.find(matcher).flags.dirty,
            'is-invalid': this.$validator.fields.find(matcher) && this.$validator.fields.find(matcher).flags.invalid && this.$validator.fields.find(matcher).flags.dirty,
        }
    }

    get formInvalid() {
        console.log(this.$validator.fields);

        return this.$validator.fields.items.some(field => field.flags.invalid);
        //return Object.keys(this.fields).some(key => this.fields[key].invalid);
    }

    /* User secrets */

    editSecretState: boolean = false;

    emptySecret: Secret = {
        description: '',
        expiration: null,
        type: 'SharedSecret',
        value: '',
        hashValue: ''
    }

    currentSecret: Secret;

    editSecret(secret: Secret | null) {
        this.editSecretState = true;

        if (secret) {
            this.currentSecret = secret;
        }
        else {
            this.currentSecret = { ...this.emptySecret };
        }
    }

    updateSecrets(item: Secret) {
        let index = this.client.clientSecrets.indexOf(this.currentSecret);

        if (index === -1) {
            this.client.clientSecrets.push(item)
        }
        else {
            this.currentSecret.description = item.description;
            this.currentSecret.type = item.type;
            this.currentSecret.value = item.value;
        }

        this.editSecretState = false;
    }

    cancelEditSecret() {
        this.editSecretState = false;
    }

    secretFields = {
        description: {
            label: 'Описание',
            sortable: false
        },
        type: {
            label: 'Тип',
            sortable: false
        },
        actions: {
            label: 'Действия'
        }
    }

    /* Allowed scopes */

    isSelectedScope(name: string): boolean {
        return this.client.allowedScopes.indexOf(name) !== -1;
    } 

    changeAllowedScope(name: string) {
        var idx = this.client.allowedScopes.indexOf(name);
        if (idx === -1) {
            this.client.allowedScopes.push(name);
        }
        else {
            this, this.client.allowedScopes.splice(idx, 1);
        }
    }

    /* Private methods */

    private loadData(clientId: string) {
        let promises: Promise<any>[] = [];
        promises.push(this.getResources());
        if (clientId) {
            promises.push(this.getClient(clientId));
        }
        else {
            this.resetClient();
        }

        this.isLoading = true;
        Promise.all(promises)
            .finally(() => {
                this.isLoading = false;
            });


    }


    
}