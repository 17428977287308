//import Vue from 'vue';
import Component from 'vue-class-component';
import apiService from '../../common/apiService';
import VueValidation from '../../common/VueValidation';

@Component({})
export default class ReferenceSiteUserToADUserPage extends VueValidation {

    public username: string;
    public password: string;

    public refADUser() {
        apiService.referenceSiteUserToAddUser(this.username, this.password)
            .then(resp => {
                alert('Ok');
            })
            .catch(err => {
                alert('Error');
            });
    }

}