var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-signin",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.refADUser($event)
        }
      }
    },
    [
      _c("h1", { staticClass: "h3 mb-3 font-weight-normal" }, [
        _vm._v("Форма привязки пользователя к AD")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "sr-only", attrs: { for: "inputLogin" } }, [
        _vm._v("Имя пользователя")
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.username,
            expression: "username"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        staticClass: "form-control",
        class: _vm.getValidateClasses("username"),
        attrs: {
          type: "text",
          id: "inputLogin",
          name: "username",
          placeholder: "Имя пользователя",
          required: "",
          autofocus: ""
        },
        domProps: { value: _vm.username },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.username = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("label", { staticClass: "sr-only", attrs: { for: "inputPassword" } }, [
        _vm._v("Пароль")
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        staticClass: "form-control",
        class: _vm.getValidateClasses("password"),
        attrs: {
          type: "password",
          id: "inputPassword",
          name: "password",
          placeholder: "Пароль",
          required: ""
        },
        domProps: { value: _vm.password },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.password = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-primary btn-block",
          attrs: { disabled: _vm.formInvalid, type: "submit" }
        },
        [_vm._v("Привязать")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }