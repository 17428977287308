export class GrantType {
    static readonly Implicit: string = 'implicit';
    static readonly Hybrid: string = "hybrid";
    static readonly AuthorizationCode: string = "authorization_code";
    static readonly ClientCredentials: string = "client_credentials";
    static readonly ResourceOwnerPassword: string = "password";
}

export class GrantTypes {
    static readonly Implicit: string[] = [GrantType.Implicit];
    static readonly ImplicitAndClientCredentials: string[] = [GrantType.Implicit, GrantType.ClientCredentials];
    static readonly Code: string[] = [GrantType.AuthorizationCode];
    static readonly CodeAndClientCredentials: string[] = [GrantType.AuthorizationCode, GrantType.ClientCredentials];
    static readonly Hybrid: string[] = [GrantType.Hybrid];
    static readonly HybridAndClientCredentials: string[] = [GrantType.Hybrid, GrantType.ClientCredentials];
    static readonly ClientCredentials: string[] = [GrantType.ClientCredentials];
    static readonly ResourceOwnerPassword: string[] = [GrantType.ResourceOwnerPassword];
    static readonly ResourceOwnerPasswordAndClientCredentials: string[] = [GrantType.ResourceOwnerPassword, GrantType.ClientCredentials];
}