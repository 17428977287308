import VueValidation from '../../common/VueValidation';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { ApiResource, Scope } from '@/types/storeTypes';
import { ActionMethod } from 'vuex';
import { Route } from 'vue-router';
import EditScope from '../../components/editApiResourceScope/EditApiResourceScope.vue';

@Component({
    components: {
        editScope: EditScope
    }
})
export default class ApiResourceEditPage extends VueValidation {

    @Getter('apiResource') apiResource: ApiResource;

    @Action('getApiResource') getApiResource: ActionMethod;
    @Action('resetApiResource') resetApiResource: ActionMethod;
    @Action('saveApiResource') saveApiResource: ActionMethod;

    isLoading: boolean = false;
    isSaving: boolean = false;

    mounted() {
        let apiResourceName = this.$route.params['name'];
        this.loadData(apiResourceName);
    }

    beforeRouteUpdate(to: Route, from: Route, next: any) {
        let apiResourceName = this.$route.params['name'];
        this.loadData(apiResourceName);
        next();
    }

    save() {
        this.isSaving = true;

        this.saveApiResource(this.apiResource)
            .finally(() => {
                this.isSaving = false;
            })
    }

    /* User Claims */

    addUserClaims() {
        this.apiResource.userClaims.push('');
    }

    updateUserClaim(index: number, claim: string) {
        this.$set(this.apiResource.userClaims, index, claim);
    }

    removeUserClaim(index: number) {
        this.apiResource.userClaims.splice(index, 1);
    }

    /*addScopeUserClaims() {
        this.apiResource.Scopes.userClaims.push('');
    }

    updateScopeUserClaim(index: number, claim: string) {
        this.$set(this.apiResource.Scopes.userClaims, index, claim);
    }

    removeUserClaim(index: number) {
        this.apiResource.userClaims.splice(index, 1);
    }*/

    /* Scopes */

    editScopeState: boolean = false;

    editScope(scope: Scope | null) {
        this.editScopeState = true;
        if (scope) {
            this.currentScope = scope;
        }
        else {
            this.currentScope = { ...this.emptyEditScope };
        }
    }

    emptyEditScope: Scope = {
        description: '',
        displayName: '',
        emphasize: false,
        name: '',
        require: false,
        showInDiscoveryDocument: true,
        userClaims: []
    };

    currentScope: Scope;

    updateScopes(item: Scope) {
        var scope = this.apiResource.scopes.find((value) => value.name === item.name);

        if (scope) {
            scope.description = item.description;
            scope.displayName = item.displayName;
            scope.emphasize = item.emphasize;
            scope.require = item.require;
            scope.showInDiscoveryDocument = item.showInDiscoveryDocument;
            scope.userClaims = item.userClaims;
        } else {
            this.apiResource.scopes.push(item);
        }

        this.editScopeState = false;
    }

    cancelEditScope() {
        this.editScopeState = false;
    }

    scopeFields = {
        name: {
            label: 'Имя',
            sortable: true
        },
        displayName: {
            label: 'Отображаемое имя',
            sortable: true
        },
        description: {
            label: 'Описание',
            sortable: false
        },
        actions: {
            label: 'Действия'
        }
    }

    private loadData(apiResourceName: string) {
        if (apiResourceName) {
            this.isLoading = true;

            this.getApiResource(apiResourceName)
                .finally(() => {
                    this.isLoading = false;
                });
        }
        else {
            this.resetApiResource();
        }
    }
}