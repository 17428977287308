import Vue from 'vue';
import axios, { AxiosInstance } from 'axios';

class HttpService {

    public axios!: AxiosInstance;

    init() {
        this.axios = axios.create();
        this.axios.defaults.baseURL = '/api';
        //Vue.$axios = this.axios;
    }

    setAuthHeader(token: string) {
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}

export default new HttpService();