var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "Основное", active: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "ClientId" } }, [
                    _vm._v("Client id")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.clientId,
                        expression: "client.clientId"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("clientId"),
                    attrs: {
                      type: "text",
                      id: "ClientId",
                      name: "clientId",
                      placeholder: "Client Id",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.client.clientId },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.client, "clientId", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "ClientName" } }, [
                    _vm._v("Название")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.clientName,
                        expression: "client.clientName"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "ClientName",
                      name: "clientName",
                      placeholder: "Название"
                    },
                    domProps: { value: _vm.client.clientName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.client, "clientName", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "allowedGrantTypes" } }, [
                    _vm._v("Grant type")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedGrantTypes,
                          expression: "selectedGrantTypes"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "form-control",
                      class: _vm.getValidateClasses("allowedGrantTypes"),
                      attrs: {
                        id: "allowedGrantTypes",
                        name: "allowedGrantTypes"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedGrantTypes = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Не выбрано")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.grantTypes, function(value, key) {
                        return _c("option", { domProps: { value: key } }, [
                          _vm._v(_vm._s(key))
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Redirect Uris")]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            _vm.addRedirectUri()
                          }
                        }
                      },
                      [_vm._v("Добавить")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.client.redirectUris, function(item, index) {
                      return _c("div", { staticClass: "input-group mb-3" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: item },
                          on: {
                            input: function($event) {
                              _vm.updateRedirectUri(index, $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-danger",
                              on: {
                                click: function($event) {
                                  _vm.removeRedirectUri(index)
                                }
                              }
                            },
                            [_vm._v("Удалить")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Post Logout Redirect Uris")]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function($event) {
                            _vm.addPostLogoutRedirectUri()
                          }
                        }
                      },
                      [_vm._v("Добавить")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.client.postLogoutRedirectUris, function(
                      item,
                      index
                    ) {
                      return _c("div", { staticClass: "input-group mb-3" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: item },
                          on: {
                            input: function($event) {
                              _vm.updatePostLogoutRedirectUri(
                                index,
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-danger",
                              on: {
                                click: function($event) {
                                  _vm.removePostLogoutRedirectUri(index)
                                }
                              }
                            },
                            [_vm._v("Удалить")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.enabled,
                        expression: "client.enabled"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "enabled" },
                    domProps: {
                      checked: Array.isArray(_vm.client.enabled)
                        ? _vm._i(_vm.client.enabled, null) > -1
                        : _vm.client.enabled
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.client.enabled,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.client, "enabled", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.client,
                                "enabled",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.client, "enabled", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "enabled" }
                    },
                    [_vm._v("Включен")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.requireClientSecret,
                        expression: "client.requireClientSecret"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "requireClientSecret" },
                    domProps: {
                      checked: Array.isArray(_vm.client.requireClientSecret)
                        ? _vm._i(_vm.client.requireClientSecret, null) > -1
                        : _vm.client.requireClientSecret
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.client.requireClientSecret,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.client,
                                "requireClientSecret",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.client,
                                "requireClientSecret",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.client, "requireClientSecret", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "requireClientSecret" }
                    },
                    [_vm._v("Require Client Secret")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.allowOfflineAccess,
                        expression: "client.allowOfflineAccess"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "allowOfflineAccess" },
                    domProps: {
                      checked: Array.isArray(_vm.client.allowOfflineAccess)
                        ? _vm._i(_vm.client.allowOfflineAccess, null) > -1
                        : _vm.client.allowOfflineAccess
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.client.allowOfflineAccess,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.client,
                                "allowOfflineAccess",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.client,
                                "allowOfflineAccess",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.client, "allowOfflineAccess", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "allowOfflineAccess" }
                    },
                    [_vm._v("Allow offline access")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.allowAccessTokensViaBrowser,
                        expression: "client.allowAccessTokensViaBrowser"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "checkbox",
                      id: "allowAccessTokensViaBrowser"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.client.allowAccessTokensViaBrowser
                      )
                        ? _vm._i(_vm.client.allowAccessTokensViaBrowser, null) >
                          -1
                        : _vm.client.allowAccessTokensViaBrowser
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.client.allowAccessTokensViaBrowser,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.client,
                                "allowAccessTokensViaBrowser",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.client,
                                "allowAccessTokensViaBrowser",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.client,
                            "allowAccessTokensViaBrowser",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "allowAccessTokensViaBrowser" }
                    },
                    [_vm._v("AllowAccessTokensViaBrowser")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.requireConsent,
                        expression: "client.requireConsent"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "requireConsent" },
                    domProps: {
                      checked: Array.isArray(_vm.client.requireConsent)
                        ? _vm._i(_vm.client.requireConsent, null) > -1
                        : _vm.client.requireConsent
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.client.requireConsent,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.client,
                                "requireConsent",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.client,
                                "requireConsent",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.client, "requireConsent", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "requireConsent" }
                    },
                    [_vm._v("Require Consent")]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("b-tab", { attrs: { title: "Настройка токенов" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "identityTokenLifetime" } }, [
                    _vm._v("Identity Token Lifetime")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.identityTokenLifetime,
                        expression: "client.identityTokenLifetime"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required" | "numeric",
                        expression: "'required' | 'numeric'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("identityTokenLifetime"),
                    attrs: {
                      type: "text",
                      id: "identityTokenLifetime",
                      name: "identityTokenLifetime",
                      placeholder: "Identity Token Lifetime",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.client.identityTokenLifetime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.client,
                          "identityTokenLifetime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "authorizationCodeLifetime" } }, [
                    _vm._v("Authorization Code Lifetime")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.authorizationCodeLifetime,
                        expression: "client.authorizationCodeLifetime"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required" | "numeric",
                        expression: "'required' | 'numeric'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("authorizationCodeLifetime"),
                    attrs: {
                      type: "text",
                      id: "authorizationCodeLifetime",
                      name: "authorizationCodeLifetime",
                      placeholder: "Authorization Code Lifetime",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.client.authorizationCodeLifetime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.client,
                          "authorizationCodeLifetime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "refreshTokenUsage" } }, [
                    _vm._v("Refresh Token Usage")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.client.refreshTokenUsage,
                          expression: "client.refreshTokenUsage"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "form-control",
                      class: _vm.getValidateClasses("refreshTokenUsage"),
                      attrs: {
                        id: "refreshTokenUsage",
                        name: "refreshTokenUsage"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.client,
                            "refreshTokenUsage",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: 0 } }, [
                        _vm._v(
                          "ReUse - the refresh token handle will stay the same when refreshing tokens"
                        )
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v(
                          "OneTime - the refresh token handle will be updated when refreshing tokens. This is the default."
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "refreshTokenExpiration" } }, [
                    _vm._v("Refresh Token Expiration")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.client.refreshTokenExpiration,
                          expression: "client.refreshTokenExpiration"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "form-control",
                      class: _vm.getValidateClasses("refreshTokenExpiration"),
                      attrs: {
                        id: "refreshTokenExpiration",
                        name: "refreshTokenExpiration"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.client,
                            "refreshTokenExpiration",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: 0 } }, [
                        _vm._v(
                          "Sliding - when refreshing the token, the lifetime of the refresh token will be renewed (by the amount specified in SlidingRefreshTokenLifetime)"
                        )
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v(
                          "Absolute - the refresh token will expire on a fixed point in time (specified by the AbsoluteRefreshTokenLifetime)"
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "accessTokenLifetime" } }, [
                    _vm._v("Access Token Lifetime")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.accessTokenLifetime,
                        expression: "client.accessTokenLifetime"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required" | "numeric",
                        expression: "'required' | 'numeric'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses("accessTokenLifetime"),
                    attrs: {
                      type: "text",
                      id: "accessTokenLifetime",
                      name: "accessTokenLifetime",
                      placeholder: "Access Token Lifetime",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.client.accessTokenLifetime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.client,
                          "accessTokenLifetime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "absoluteRefreshTokenLifetime" } },
                    [_vm._v("Absolute Refresh Token Lifetime")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.absoluteRefreshTokenLifetime,
                        expression: "client.absoluteRefreshTokenLifetime"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required" | "numeric",
                        expression: "'required' | 'numeric'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses(
                      "absoluteRefreshTokenLifetime"
                    ),
                    attrs: {
                      type: "text",
                      id: "absoluteRefreshTokenLifetime",
                      name: "absoluteRefreshTokenLifetime",
                      placeholder: "Absolute Refresh Token Lifetime",
                      required: "",
                      autofocus: ""
                    },
                    domProps: {
                      value: _vm.client.absoluteRefreshTokenLifetime
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.client,
                          "absoluteRefreshTokenLifetime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "slidingRefreshTokenLifetime" } },
                    [_vm._v("Sliding Refresh Token Lifetime")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.client.slidingRefreshTokenLifetime,
                        expression: "client.slidingRefreshTokenLifetime"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required" | "numeric",
                        expression: "'required' | 'numeric'"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.getValidateClasses(
                      "slidingRefreshTokenLifetime"
                    ),
                    attrs: {
                      type: "text",
                      id: "slidingRefreshTokenLifetime",
                      name: "slidingRefreshTokenLifetime",
                      placeholder: "Sliding Refresh Token Lifetime",
                      required: "",
                      autofocus: ""
                    },
                    domProps: { value: _vm.client.slidingRefreshTokenLifetime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.client,
                          "slidingRefreshTokenLifetime",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("b-tab", { attrs: { title: "Client secrets" } }, [
            _vm.editSecretState
              ? _c(
                  "div",
                  { staticClass: "add-secret" },
                  [
                    _c("edit-secret", {
                      attrs: { "input-secret": _vm.currentSecret },
                      on: {
                        updated: _vm.updateSecrets,
                        cancel: _vm.cancelEditSecret
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editSecretState,
                    expression: "!editSecretState"
                  }
                ]
              },
              [
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          _vm.editSecret(null)
                        }
                      }
                    },
                    [_vm._v("Добавить")]
                  )
                ]),
                _vm._v(" "),
                _c("b-table", {
                  attrs: {
                    striped: "",
                    hover: "",
                    items: _vm.client.clientSecrets,
                    fields: _vm.secretFields
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function(data) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  _vm.editSecret(data.item)
                                }
                              }
                            },
                            [_vm._v("Редактировать")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("b-tab", { attrs: { title: "Разрешенные скоупы" } }, [
            !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _c("h3", [_vm._v("Identity ресурсы")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(_vm.resources.identityResources, function(ir) {
                        return _c(
                          "div",
                          { staticClass: "form-group form-check" },
                          [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", id: ir.name },
                              domProps: {
                                checked: _vm.isSelectedScope(ir.name)
                              },
                              on: {
                                click: function($event) {
                                  _vm.changeAllowedScope(ir.name)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: ir.name }
                              },
                              [_vm._v(_vm._s(ir.displayName))]
                            )
                          ]
                        )
                      })
                    ),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Api ресурсы")]),
                    _vm._v(" "),
                    _vm._l(_vm.resources.apiResources, function(ar) {
                      return _c(
                        "div",
                        [
                          _c("h4", [_vm._v(_vm._s(ar.displayName))]),
                          _vm._v(" "),
                          _vm._l(ar.scopes, function(ars) {
                            return _c(
                              "div",
                              { staticClass: "form-group form-check" },
                              [
                                _c("input", {
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox", id: ars.name },
                                  domProps: {
                                    checked: _vm.isSelectedScope(ars.name)
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.changeAllowedScope(ars.name)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: ars.name }
                                  },
                                  [_vm._v(_vm._s(ars.displayName))]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.isSaving },
          on: {
            click: function($event) {
              _vm.save()
            }
          }
        },
        [_vm._v("Сохранить")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }