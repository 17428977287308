import * as Oidc from 'oidc-client';
import { GetterTree, ActionTree, MutationTree } from 'vuex';

import httpService from '../common/httpService';
import { AuthState } from '@/types/storeTypes';

//const config: Oidc.OidcClientSettings = {
//    authority: "http://auth.krasgmu.ru",
//    client_id: "oauth_spa",
//    redirect_uri: "http://auth.krasgmu.ru/callback",
//    response_type: "id_token token",
//    scope: "openid profile oauthConfigApi",
//    post_logout_redirect_uri: "http://auth.krasgmu.ru/"
//}

const developmentMode = false;
let oidcHost = '';

if (developmentMode) {
    oidcHost = 'http://localhost:5000';
}
else {
    oidcHost = 'https://auth.krasgmu.ru';
}

const config: Oidc.UserManagerSettings = {
    authority: oidcHost,
    client_id: "oauth_spa",
    redirect_uri: oidcHost + "/callback",
    response_type: "id_token token",
    scope: "openid profile oauthConfigApi",
    post_logout_redirect_uri: oidcHost + "/",
    revokeAccessTokenOnSignout: true,
    automaticSilentRenew: true,
    silent_redirect_uri: oidcHost + '/silentcallback.html'
    //automaticSilentRenew: true;
}

const mgr = new Oidc.UserManager(config);

const state: AuthState = {
    user: null,
    isSignedIn: false
};

const getters: GetterTree<AuthState, any> = {
    user(state) {
        return state.user;
    },
    isSignedIn(state) {
        return state.isSignedIn;
    }
}

const actions: ActionTree<AuthState, any> = {
    login(context) {
        let promise = mgr.signinRedirect();

        promise
            .then(data => {

            })
            .catch(error => {
                console.log(error);
            });

        return promise;
    },
    getUser(context) {
        let promise = mgr.getUser();

        promise
            .then(user => {
                if (user) {
                    if (user.expired) {
                        mgr.signinSilent();
                    }
                    context.commit('setUser', user);
                    let token = user.access_token;
                    console.log(token);
                    let idToken = user.id_token;
                    console.log(idToken);
                    httpService.setAuthHeader(token);
                }
            })
            .catch(error => {
                console.log(error);
            });
        return promise;
    },
    logout(context) {
        let promise = mgr.signoutRedirect();
        mgr.revokeAccessToken();
        promise
            .then(data => {
                context.commit('cleareUser');
            })
            .catch(error => {
                console.log(error);
            });
        return promise;
    }
}

const mutations: MutationTree<AuthState> = {
    setUser(state, user) {
        state.user = user;
        state.isSignedIn = true;
    },
    cleareUser(state) {
        state.user = null;
        state.isSignedIn = false;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}

