import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/home/home.vue';
import OidcCallback from '../views/oidcCallback/oidcCallback.vue';
import oAuthClients from '../views/oAuthClients/oAuthClients.vue';
import editOAuthClient from '../views/oAuthClients/editOAuthClient.vue';

import IdentityResourceListPage from '../views/identityResource/irList.vue';
import IdentityResourceEditPage from '../views/identityResource/irEdit.vue';

import ApiResourceListPage from '../views/apiResource/arList.vue';
import ApiResourceEditPage from '../views/apiResource/arEdit.vue';

import RefADUserPage from '../views/ReferenceSiteUserToADUser/refADUser.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/callback',
            component: OidcCallback
        },
        {
            path: '/oAuthClients',
            component: oAuthClients
        },
        {
            path: '/oAuthClient/:clientId?',
            component: editOAuthClient
        },
        {
            path: '/identityResources',
            component: IdentityResourceListPage
        },
        {
            path: '/identityResource/:name?',
            component: IdentityResourceEditPage
        },
        {
            path: '/apiResources',
            component: ApiResourceListPage
        },
        {
            path: '/apiResource/:name?',
            component: ApiResourceEditPage
        },
        {
            path: '/refADUser',
            component: RefADUserPage
        }
    ]
})