var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-bordered" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.items, function(item) {
        return _c("tr", [
          _c("td", [
            _vm._v(
              "\n                " + _vm._s(item.clientId) + "\n            "
            )
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n                " + _vm._s(item.clientName) + "\n            "
            )
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n                " +
                _vm._s(item.clientDescription) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm._t("actions", [_c("div", [_vm._v("Элементы управления")])], {
                item: item
              })
            ],
            2
          )
        ])
      })
    ),
    _vm._v(" "),
    _c("tfoot", [
      _c("tr", [
        _c("td", [_vm._v("Всего элементов:")]),
        _vm._v(" "),
        _c("td", { attrs: { colspan: "3" } }, [_vm._v(_vm._s(_vm.totalCount))])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Id")]),
        _vm._v(" "),
        _c("td", [_vm._v("Ноименование")]),
        _vm._v(" "),
        _c("td", [_vm._v("Описание")]),
        _vm._v(" "),
        _c("td")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }