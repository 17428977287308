import VueValidate from '../../common/VueValidation';
import { Prop, Component, Emit } from 'vue-property-decorator';
import { Scope } from '../../types/storeTypes';
import { ActionMethod } from 'vuex';

@Component
export default class EditApiResourceScopeComponent extends VueValidate {
    @Prop() scope: Scope;

    item: Scope = this.scope;
    nameReadOnly: boolean = false;

    mounted() {
        if (this.scope.name.length > 0) {
            this.nameReadOnly = true;
        }
    }

    /* User Claims */
    @Emit('updated')
    save() {
        return this.item;
    }

    @Emit('cancel')
    cancel() {

    }

    addUserClaims() {
        this.item.userClaims.push('');
    }

    updateUserClaim(index: number, claim: string) {
        this.$set(this.item.userClaims, index, claim);
    }

    removeUserClaim(index: number) {
        this.item.userClaims.splice(index, 1);
    }
}