import VueValidation from '../../common/VueValidation';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { Secret } from '../../types/storeTypes';

@Component
export default class EditSecretComponent extends VueValidation {
    @Prop() inputSecret: Secret

    secret: Secret = this.inputSecret;

    @Emit('updated')
    save() {
        return this.secret;
    }

    @Emit('cancel')
    cancel() {

    }
}