import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Modal } from 'bootstrap-vue';

export interface CDialog extends Vue {
    showModal(): Promise<any>;
}

@Component({})
export default class ConfirmDialog extends Vue {
    @Prop()
    public title!: string;

    @Prop()
    public confirmText!: string

    $refs!: {
        modalRef: Modal
    }

    showModal(): Promise<any> {
        return new Promise((resolve, reject) => {
            let ok: boolean = false;
            this.$refs.modalRef.show();
            this.$refs.modalRef.$once('ok', () => {
                ok = true;
            });
            this.$refs.modalRef.$once('hidden', () => {
                if (ok) {
                    resolve();
                }
                else { reject(); }
            });
        })
    }
}