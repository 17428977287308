import Vue from 'vue';
import * as Oidc from 'oidc-client';
import { Action } from 'vuex-class';
import Component from 'vue-class-component';
import { User } from 'oidc-client';
import { ActionMethod } from 'vuex';

//import { GET_USER } from '../../store/actions.type';
@Component({})
export default class oidcCallback extends Vue {

    @Action getUser!: ActionMethod;

    mounted() {
        console.log('Callback commponent mounted');
        let mgr = new Oidc.UserManager({});
        console.log('start signinRedirectCallback');
        mgr.signinRedirectCallback()
            .then(() => {
                console.log('Login success. Get current user');

                this.getUser()
                    .then((user: User) => {
                            console.log('Current user success. Redirect to main page');
                            this.$router.push({ path: '/' });
                        })


                //this.$router.push({ path: '/' });
            })
            .catch(e => {
                console.log('Error');
                console.log(e);
            })
    }
};