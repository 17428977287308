import { User } from 'oidc-client';

export interface AuthState {
    user: User | null;
    isSignedIn: boolean;
}

export interface ManageState {
    clients: OAuthClient[];
    client: OAuthClient;

    identityResources: IdentityResource[];
    identityResource: IdentityResource;

    apiResources: ApiResource[];
    apiResource: ApiResource;

    resources: Resources | null;
}

export interface Resources {
    apiResources: ApiResource[];
    identityResources: IdentityResource[];
}

export interface OAuthClient {
    enabled: boolean;
    clientId: string;
    clientName: string;
    requireConsent: boolean;
    allowedGrantTypes: string[];
    backChannelLogoutSessionRequired: boolean;
    alwaysIncludeUserClaimsInIdToken: boolean;
    identityTokenLifetime: number;
    accessTokenLifetime: number;
    authorizationCodeLifetime: number;
    absoluteRefreshTokenLifetime: number;
    slidingRefreshTokenLifetime: number;
    consentLifetime: number | null;
    refreshTokenUsage: TokenUsage;
    updateAccessTokenClaimsOnRefresh: boolean;
    refreshTokenExpiration: TokenExpiration;
    accessTokenType: AccessTokenType;
    enableLocalLogin: boolean;
    identityProviderRestrictions: string[];
    includeJwtId: boolean;
    claims: Claim[];
    alwaysSendClientClaims: boolean;
    clientClaimsPrefix: string;
    pairWiseSubjectSalt: string;
    allowedScopes: string[];
    allowOfflineAccess: boolean;
    backChannelLogoutUri: string;
    protocolType: string;
    clientSecrets: Secret[];
    requireClientSecret: boolean;
    clientUri: string;
    logoUri: string;
    allowedCorsOrigins: string[];
    requirePkce: boolean;
    allowPlainTextPkce: boolean;
    allowAccessTokensViaBrowser: boolean;
    redirectUris: string[];
    postLogoutRedirectUris: string[];
    frontChannelLogoutUri: string;
    frontChannelLogoutSessionRequired: boolean;
    allowRememberConsent: boolean;
    created: Date;
}

export interface IdentityResource {
    enabled: boolean;
    name: string;
    displayName: string;
    description: string;
    userClaims: string[];
    required: boolean;
    emphasize: boolean;
    showInDiscoveryDocument: boolean
}

export interface ApiResource {
    enabled: boolean;
    name: string;
    displayName: string;
    description: string;
    userClaims: string[];
    apiSecrets: Secret[];
    scopes: Scope[];
}

export interface Claim {
    name: string;
    value: string;
}

export interface Secret {
    description: string;
    value: string;
    hashValue: string;
    expiration: Date | null;
    type: string;
}

export interface Scope {
    name: string;
    displayName: string;
    description: string;
    require: boolean;
    emphasize: boolean;
    showInDiscoveryDocument: boolean;
    userClaims: string[];
}

export enum TokenUsage {
    reUse = 0,
    oneTimeOnly = 1
}

export enum TokenExpiration {
    sliding = 0,
    absolute = 1
}

export enum AccessTokenType {
    jwt = 0,
    reference = 1
}

//export type ActionFunction = () => Promise<any>;