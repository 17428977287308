var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.addClient } },
          [_vm._v("Добавить")]
        )
      ]),
      _vm._v(" "),
      _c("client-list", {
        attrs: { items: _vm.clients },
        on: {
          edit: function($event) {
            _vm.edit($event)
          },
          remove: function($event) {
            _vm.remove($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(data) {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-default",
                    attrs: {
                      to: { path: "/oAuthClient/" + data.item.clientId }
                    }
                  },
                  [_vm._v("Редактировать")]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              _vm.showModal()
            }
          }
        },
        [_vm._v("show modal")]
      ),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "modalRef",
        attrs: { title: "Title", "confirm-text": "text" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }