var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-md navbar-dark fixed-top bg-dark" },
      [
        _c("router-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
          _vm._v("Главная")
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarCollapse" }
          },
          [
            _c(
              "ul",
              { staticClass: "navbar-nav mr-auto" },
              [
                _vm.isSignedIn
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: { tag: "li", to: "/oAuthClients" }
                      },
                      [
                        _c("a", { staticClass: "nav-link" }, [
                          _vm._v("Api клиенты")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isSignedIn
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: { tag: "li", to: "/identityResources" }
                      },
                      [
                        _c("a", { staticClass: "nav-link" }, [
                          _vm._v("Identity resources")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isSignedIn
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: { tag: "li", to: "/apiResources" }
                      },
                      [
                        _c("a", { staticClass: "nav-link" }, [
                          _vm._v("Api resources")
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.isSignedIn
              ? _c("div", { staticClass: "username-box" }, [
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.user.profile.name))
                  ]),
                  _vm._v(" "),
                  _c("button", { on: { click: _vm.logout } }, [_vm._v("Выход")])
                ])
              : _c("div", [
                  _c("button", { on: { click: _vm.login } }, [_vm._v("Вход")])
                ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }