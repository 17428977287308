import Vue from 'vue';
import headerComponent from './components/header/header.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        headerComponent: headerComponent
    }
})
export default class App extends Vue {

};