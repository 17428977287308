import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { IdentityResource } from '@/types/storeTypes';
import { ActionMethod } from 'vuex';

@Component
export default class IdentityResourceListPage extends Vue {
    @Getter('identityResources') identityResources: IdentityResource[];
    @Getter('identityResourcesCount') count: number;

    @Action('getIdentityResources') getIdentityResources: ActionMethod;

    isLoading: boolean = false;

    addIdResource() {
        this.$router.push({ path: '/identityResource' });
    }

    mounted() {
        this.isLoading = true;

        this.getIdentityResources()
            .finally(() => {
                this.isLoading = false;
            })
    }

    fields = {
        name: {
            label: 'Имя',
            sortable: true
        },
        displayName: {
            label: 'Отображаемое имя',
            sortable: true
        },
        actions: {
            label: 'Действия',
            sortable: false
        }
    }
}