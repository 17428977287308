var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Description" } }, [_vm._v("Описание")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.secret.description,
                expression: "secret.description"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "Description",
              name: "description",
              placeholder: "Описание"
            },
            domProps: { value: _vm.secret.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.secret, "description", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Value" } }, [_vm._v("Значение")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.secret.value,
                expression: "secret.value"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: _vm.getValidateClasses("value"),
            attrs: {
              type: "password",
              id: "Value",
              name: "value",
              placeholder: "Значение"
            },
            domProps: { value: _vm.secret.value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.secret, "value", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Type" } }, [_vm._v("Тип")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.secret.type,
                expression: "secret.type"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: _vm.getValidateClasses("type"),
            attrs: {
              type: "text",
              id: "Type",
              name: "type",
              placeholder: "Тип"
            },
            domProps: { value: _vm.secret.type },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.secret, "type", $event.target.value)
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.save } },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.cancel } },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }