var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Name" } }, [_vm._v("Имя")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.name,
                expression: "item.name"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: _vm.getValidateClasses("name"),
            attrs: {
              type: "text",
              id: "Name",
              name: "name",
              placeholder: "Имя",
              required: "",
              autofocus: "",
              readonly: _vm.nameReadOnly
            },
            domProps: { value: _vm.item.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.required,
                expression: "item.required"
              }
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "Required" },
            domProps: {
              checked: Array.isArray(_vm.item.required)
                ? _vm._i(_vm.item.required, null) > -1
                : _vm.item.required
            },
            on: {
              change: function($event) {
                var $$a = _vm.item.required,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.item, "required", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.item,
                        "required",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.item, "required", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: "Required" } },
            [_vm._v("Обязательный")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.emphasize,
                expression: "item.emphasize"
              }
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "Emphasize" },
            domProps: {
              checked: Array.isArray(_vm.item.emphasize)
                ? _vm._i(_vm.item.emphasize, null) > -1
                : _vm.item.emphasize
            },
            on: {
              change: function($event) {
                var $$a = _vm.item.emphasize,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.item, "emphasize", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.item,
                        "emphasize",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.item, "emphasize", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: "Emphasize" } },
            [_vm._v("Подчеркивать (особое значение)")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.showInDiscoveryDocument,
                expression: "item.showInDiscoveryDocument"
              }
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "ShowInDiscoveryDocument" },
            domProps: {
              checked: Array.isArray(_vm.item.showInDiscoveryDocument)
                ? _vm._i(_vm.item.showInDiscoveryDocument, null) > -1
                : _vm.item.showInDiscoveryDocument
            },
            on: {
              change: function($event) {
                var $$a = _vm.item.showInDiscoveryDocument,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.item,
                        "showInDiscoveryDocument",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.item,
                        "showInDiscoveryDocument",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.item, "showInDiscoveryDocument", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "ShowInDiscoveryDocument" }
            },
            [_vm._v("Показывать в Discovery")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "displayName" } }, [
            _vm._v("Отоброжаемое имя")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.displayName,
                expression: "item.displayName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: _vm.getValidateClasses("displayName"),
            attrs: {
              type: "text",
              id: "displayName",
              name: "displayName",
              placeholder: "Отоброжаемое имя",
              required: "",
              autofocus: ""
            },
            domProps: { value: _vm.item.displayName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "displayName", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Клаймы")]),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                on: {
                  click: function($event) {
                    _vm.addUserClaims()
                  }
                }
              },
              [_vm._v("Добавить")]
            ),
            _vm._v(" "),
            _vm._l(_vm.item.userClaims, function(item, index) {
              return _c("div", { staticClass: "input-group mb-3" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: item },
                  on: {
                    input: function($event) {
                      _vm.updateUserClaim(index, $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      on: {
                        click: function($event) {
                          _vm.removeUserClaim(index)
                        }
                      }
                    },
                    [_vm._v("Удалить")]
                  )
                ])
              ])
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.save } },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.cancel } },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }