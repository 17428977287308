var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.addIdResource } },
          [_vm._v("Добавить")]
        )
      ]),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.identityResources,
          fields: _vm.fields
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(data) {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-default",
                    attrs: {
                      to: { path: "/identityResource/" + data.item.name }
                    }
                  },
                  [_vm._v("Редактировать")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }