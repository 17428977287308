import Vue from 'vue';

import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { OAuthClient } from '@/types/storeTypes';

import oAuthClientList from '../../components/oAuthClientList/oAuthClientList.vue';
import confirmDialog from '../../components/confirmDialog/confirmDialog.vue';
import { ConfirmDialog } from '../../types/componentTypes';
import { ActionMethod } from 'vuex';


@Component({
    components: {
        'client-list': oAuthClientList,
        'confirm-dialog': confirmDialog
    }
})
export default class oAuthClients extends Vue {
    @Action getApiClients!: ActionMethod;

    @Getter clients!: OAuthClient[];
    @Getter clientsCount!: number;

    $refs!: {
        modalRef: ConfirmDialog
    }

    addClient() {
        this.$router.push({ path: '/oAuthClient' });
    }

    mounted() {
        this.getApiClients();
    }

    edit(item: OAuthClient): void {
        alert('Edit ' + item.clientId);
        this.$router.push({ path: '/oAuthClient', params: { 'clientId': item.clientId } });
    }

    remove(item: OAuthClient): void {
        alert('Remove ' + item.clientId);
    }

    showModal(): void {
        this.$refs.modalRef.showModal()
            .then(() => {
                alert('confirmed');
            })
            .catch(() => {
                alert('cancel')
            });
    }
};