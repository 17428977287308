import Vue from 'vue';
//import { mapState, mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User } from 'oidc-client';
//import { LOGIN, LOGOUT } from '../../store/actions.type';

@Component({})
export default class HeaderComponent extends Vue {

    @Action logout!: Function;
    @Action login!: Function;


    @Getter isSignedIn: boolean | undefined;
    @Getter user: User | undefined;
    /*computed: {
        ...mapState({
            user: state => state.auth.user
        }),
        ...mapGetters({
            isSignedIn: 'isSignedIn'
        })
    }*/
};