import { render, staticRenderFns } from "./editOAuthClient.vue?vue&type=template&id=7fc0f142&"
import script from "./editOAuthClient.ts?vue&type=script&lang=js&"
export * from "./editOAuthClient.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\web\\KrasGMU.Sso\\KrasGMU.Sso\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('7fc0f142', component.options)
    } else {
      api.reload('7fc0f142', component.options)
    }
    module.hot.accept("./editOAuthClient.vue?vue&type=template&id=7fc0f142&", function () {
      api.rerender('7fc0f142', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/views/oAuthClients/editOAuthClient.vue"
export default component.exports