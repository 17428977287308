import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { OAuthClient } from '@/types/storeTypes';


@Component({})
export default class OAuthClientList extends Vue {
    @Prop() items!: OAuthClient[];

    get totalCount() {
        return this.items.length;
    }

    @Emit()
    edit(item: OAuthClient) {

    }

    @Emit()
    remove(item: OAuthClient) {

    }
}