import apiService from '../common/apiService';
import { GetterTree, ActionTree, MutationTree } from 'vuex';

import { ManageState, OAuthClient, TokenUsage, TokenExpiration, AccessTokenType, IdentityResource, ApiResource, Resources } from '../types/storeTypes';

const emptyClient: OAuthClient = {
    backChannelLogoutSessionRequired: true,
    alwaysIncludeUserClaimsInIdToken: false,
    identityTokenLifetime: 300,
    accessTokenLifetime: 3600,
    authorizationCodeLifetime: 300,
    absoluteRefreshTokenLifetime: 2592000,
    slidingRefreshTokenLifetime: 1296000,
    consentLifetime: null,
    refreshTokenUsage: TokenUsage.oneTimeOnly,
    updateAccessTokenClaimsOnRefresh: false,
    refreshTokenExpiration: TokenExpiration.absolute,
    accessTokenType: AccessTokenType.jwt,
    enableLocalLogin: true,
    identityProviderRestrictions: [],
    includeJwtId: false,
    claims: [],
    alwaysSendClientClaims: false,
    clientClaimsPrefix: 'client_',
    pairWiseSubjectSalt: null,
    allowedScopes: [],
    allowOfflineAccess: false,
    backChannelLogoutUri: null,
    enabled: true,
    clientId: '',
    protocolType: 'oidc',
    clientSecrets: [],
    requireClientSecret: true,
    clientName: '',
    clientUri: null,
    logoUri: null,
    allowedCorsOrigins: [],
    requireConsent: true,
    allowedGrantTypes: [],
    requirePkce: false,
    allowPlainTextPkce: false,
    allowAccessTokensViaBrowser: false,
    redirectUris: [],
    postLogoutRedirectUris: [],
    frontChannelLogoutUri: null,
    frontChannelLogoutSessionRequired: true,
    allowRememberConsent: true,
    created: new Date()
}

const emptyIdentityResource: IdentityResource = {
    enabled: true,
    name: '',
    description: '',
    displayName: '',
    userClaims: [],
    required: false,
    emphasize: false,
    showInDiscoveryDocument: true
}

const emptyApiResource: ApiResource = {
    enabled: true,
    name: '',
    description: '',
    displayName: '',
    userClaims: [],
    apiSecrets: [],
    scopes: []
}

const state: ManageState = {
    clients: [],
    client: { ...emptyClient },

    identityResources: [],
    identityResource: { ...emptyIdentityResource },

    apiResources: [],
    apiResource: { ...emptyApiResource },

    resources: {
        apiResources: [],
        identityResources: []
    }
}

const getters: GetterTree<ManageState, any> = {
    clients(state) {
        return state.clients;
    },
    client(state) {
        return state.client;
    },
    clientsCount(state) {
        return state.clients.length;
    },

    identityResources(state) {
        return state.identityResources;
    },
    identityResource(state) {
        return state.identityResource;
    },
    identityResourceCount(state) {
        return state.identityResources.length;
    },

    apiResources(state) {
        return state.apiResources;
    },
    apiResource(state) {
        return state.apiResource;
    },
    apiResourceCount(state) {
        return state.apiResources.length;
    },
    resources(state) {
        return state.resources;
    }
}

const actions: ActionTree<ManageState, any> = {
    getApiClients(context) {
        return apiService.getApiClients()
            .then(clients => {
                context.commit('setApiClients', clients);
            })
    },
    getClient(context, clientId: string) {
        return apiService.getClient(clientId)
            .then(client => {
                context.commit('setClient', client);
            })
    },
    resetClient(context) {
        context.commit('resetClient');
    },
    saveClient(context, client) {
        return apiService.saveClient(client)
            .then(updatedClient => {
                context.commit('setClient', updatedClient);
            })
    },

    getIdentityResources(context) {
        return apiService.getIdentityResources()
            .then(idResources => {
                context.commit('setIdentityResources', idResources);
            })
    },
    getIdentityResource(context, name: string) {
        return apiService.getIdentityResource(name)
            .then(idResource => {
                context.commit('setIdentityResource', idResource);
            })
    },
    resetIdentityResource(context) {
        context.commit('resetIdentityResource');
    },
    saveIdentityResource(context, idResource: IdentityResource) {
        return apiService.saveIdentityResource(idResource)
            .then(updatedIdResource => {
                context.commit('setIdentityResource', updatedIdResource);
            })
    },

    getApiResources(context) {
        return apiService.getApiResources()
            .then(apiResources => {
                context.commit('setApiResources', apiResources);
            })
    },
    getApiResource(context, name: string) {
        return apiService.getApiResource(name)
            .then(apiResource => {
                context.commit('setApiResource', apiResource);
            })
    },
    resetApiResource(context) {
        context.commit('resetApiResource');
    },
    saveApiResource(context, apiResource: ApiResource) {
        return apiService.saveApiResource(apiResource)
            .then(updatedApiResource => {
                context.commit('setApiResource', updatedApiResource);
            })
    },
    getResources(context) {
        return apiService.getResources()
            .then(res => {
                context.commit('setResources', res);
            })
    }
}

const mutations: MutationTree<ManageState> = {
    setApiClients(state, clients) {
        state.clients = clients;
    },
    setClient(state, client: OAuthClient) {
        state.client = client;
    },
    resetClient(state) {
        state.client = { ...emptyClient };
    },

    setIdentityResources(state, idResources: IdentityResource[]) {
        state.identityResources = idResources;
    },
    setIdentityResource(state, idResource: IdentityResource) {
        state.identityResource = idResource;
    },
    resetIdentityResource(state) {
        state.identityResource = { ...emptyIdentityResource };
    },

    setApiResources(state, apiResources: ApiResource[]) {
        state.apiResources = apiResources;
    },
    setApiResource(state, apiResource: ApiResource) {
        state.apiResource = apiResource;
    },
    resetApiResource(state) {
        state.apiResource = { ...emptyApiResource };
    },
    setResources(state, resources: Resources) {
        state.resources = resources;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}