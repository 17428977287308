import { render, staticRenderFns } from "./home.vue?vue&type=template&id=3f16d7b7&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\web\\KrasGMU.Sso\\KrasGMU.Sso\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('3f16d7b7', component.options)
    } else {
      api.reload('3f16d7b7', component.options)
    }
    module.hot.accept("./home.vue?vue&type=template&id=3f16d7b7&", function () {
      api.rerender('3f16d7b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/views/home/home.vue"
export default component.exports